<script>
import Layout from "../../layouts/main";
import StudentLayout from '../../layouts/studentLayout';
import moment from 'moment';
import errorHandler from '../../../helpers/errorHandler';
import Swal from "sweetalert2";
import ExportExcel from '../../../components/exportExcel.vue';

/**
 * Starter component
 */
export default {
  page: {
    title: "Views list",
  },
  head() {
    return {
      title: `Views`,
    };
  },
  name: "Student-Views",
  components:{
    Layout,
    StudentLayout,
    ExportExcel
  },
  filters:{
    formatDate(value){
    if(value){
        return moment(String(value)).format('hh:mm A DD/MM/YYYY')
    }
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    decView(video_id){
      Swal.fire({
        title: "Submit minutes to decrement",
        input: "number",
        inputAttributes: {
          min: 0
        },
        showCancelButton: true,
        confirmButtonText: "Decrement",
        showLoaderOnConfirm: true,
        preConfirm: async (minutes) => {
          this.$store.dispatch('video/decMinutes',{video_id:video_id,minutes:minutes,stud_id:this.$route.params.student}).then(async ()=>{
            errorHandler.methods.successful(`You have decrement the views by value ${minutes}`)
            await this.$store.dispatch("video/getAllVideosViews",this.$route.params.student).then( ()=>{}).catch( err => {
              // errorHandler.methods.errorMessage(err);
              console.log("No items")
            });
          }).catch( err =>{
            errorHandler.methods.error(err);
          })
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
    },
    incView(video_id){
      Swal.fire({
        title: "Submit minutes to decrement",
        input: "number",
        inputAttributes: {
          min: 0
        },
        showCancelButton: true,
        confirmButtonText: "Increment",
        showLoaderOnConfirm: true,
        preConfirm: async (minutes) => {
          this.$store.dispatch('video/incMinutes',{video_id:video_id,minutes:minutes,stud_id:this.$route.params.student}).then( async ()=>{
            errorHandler.methods.successful(`You have increment the views by value ${minutes}`)
            await this.$store.dispatch("video/getAllVideosViews",this.$route.params.student).then( ()=>{}).catch( err => {
              // errorHandler.methods.errorMessage(err);
              console.log("No items")
            });
          }).catch( err =>{
            errorHandler.methods.error(err);
          })
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
    }
  },
  async created(){
    await this.$store.dispatch('student/getStudentInfo',this.$route.params.student).then(()=>{
        this.profileData = this.$store.getters['student/getStudent'];
    }).catch( err =>{
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });
    await this.$store.dispatch('statistics/getStudentStat',this.$route.params.student).then(()=>{
        this.number = this.$store.getters['statistics/studentCount'];
    }).catch( err =>{
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });

    await this.$store.dispatch("video/getAllVideosViews",this.$route.params.student).then( ()=>{}).catch( err => {
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });  
    },
    computed: {
      tableFields() {
      return this.fields.filter(f => f.selected);
    },
    scores() {
      return this.$store.getters["video/allViews"];
    },
    rows() {
      return this.scores.length;
    },
    
  },
  data: () => ({
    profileData: null,    
    totalRows:0,
    recordId: null,
    title: "Vidoes views list",
    items: [
      {
        text: "Manage",
        href: "/home"
      },
      {
        text: "Videos Views",
        href: "#"
      }
    ],
    perPage: 20,
    currentPage: 1,
    pageOptions: [10, 20, 30],
    filter: "",
    sortBy: "number",
    sortDesc: false,
    filterOn: ["number","name","description"],
    fields: [
      {
        key: "bunny_video.video_name",
        label: "Name",
        sortable: true,
        selected: true
      },
      {
        key: "duration_minutes",
        label: "Remaining minutes",
        sortable: true,
        selected: true
      },
      {
        key: "action",
        label: "",
        sortable: false,
        selected: true,
        disabled: true
      }
    ]
  })
};
</script>

<template>
  <Layout>
    <StudentLayout :student="profileData">
      <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Display&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;Records
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                <export-excel :jsonData="scores" v-if="$store.getters['user/role'] == 0"></export-excel>
                  <label class="d-inline-flex align-items-center mt-2">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                  <b-dropdown
                    id="dropdown-offset"
                    offset="250"
                    variant="link"
                    class="btn-icon-only"
                  >
                    <template #button-content>
                      <i class="fas fa-th-large iconGray"></i>
                    </template>
                    <b-dropdown-form>
                      <b-form-checkbox
                        v-for="(field, index) in fields"
                        :key="index"
                        class="mb-3"
                        v-model="field.selected"
                        :disabled="field.disabled"
                        >{{ field.label }}</b-form-checkbox
                      >
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
              </div>
              <!-- End search -->
            </div>
            <b-table
              table-class="table table-centered w-100 table-hover"
              thead-tr-class="bg-light"
              tbody-tr-class="clickableRow"
              :items="scores"
              :fields="tableFields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
            <template v-slot:cell(timestamp)="data">
              {{ data.item.timestamp | formatDate }}
            </template>
            <template v-slot:cell(action)="data">
              <div style="width: 15rem;">
                <span class="mr-1"><b-button variant="danger" @click="decView(data.item.bunny_video.video_id)">- Decrement</b-button></span>
                <span class="mr-1"><b-button variant="success" @click="incView(data.item.bunny_video.video_id)">+ Increment</b-button></span>
              </div>
            </template>
            </b-table>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="(filter == '' )?rows:totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </StudentLayout>
  </Layout>
</template>

<style scoped>
ul {
  list-style-type: none;
}

.tab-content {
  border: none;
}

.list-group-item {
  border: none;
}

i {
  font-size: 20px;
}
</style>
